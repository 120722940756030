"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOverlap = exports.isIntersecting = void 0;
function isIntersecting(box1, box2) {
    var rect1 = {
        left: box1.x,
        right: box1.x + box1.width,
        top: box1.y,
        bottom: box1.y + box1.height,
    };
    var rect2 = {
        left: box2.x,
        right: box2.x + box2.width,
        top: box2.y,
        bottom: box2.y + box2.height,
    };
    return (rect1.left <= rect2.right && rect2.left <= rect1.right && rect1.top <= rect2.bottom && rect2.top <= rect1.bottom);
}
exports.isIntersecting = isIntersecting;
function getOverlap(rect1, rect2) {
    var xOverlap = Math.min(rect1.x + rect1.width, rect2.x + rect2.width) - Math.max(rect1.x, rect2.x);
    var yOverlap = Math.min(rect1.y + rect1.height, rect2.y + rect2.height) - Math.max(rect1.y, rect2.y);
    var intersectionArea = xOverlap * yOverlap;
    var rect2Area = rect2.width * rect2.height;
    return Math.max(0, Math.min(1, intersectionArea / rect2Area));
}
exports.getOverlap = getOverlap;

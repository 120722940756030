import "../CommonFrontend/AcceptFlow/ReactComponents/FormScreen.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../CommonFrontend/AcceptFlow/ReactComponents/FormScreen.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VUy3LbIBTd+yvoojP1Ao/kvFS86y9k0aUGwZV0EwQUUIzd6b93hBpJjpPUTUcLDZdzLuc+N7YQNTcZ+bkixBqPAY1mxIHiAZ9gt/q12oyQPEGEUcYx8sTdF0rTgaIw+r4XArwvy7xSdw8PeVgvmNvE3KMMLSNFZuNuRUgL2LRhPlfGSXDUcYm9ZyTPss+DWaK3ih8YaRzKwWAVF0AxQOcZEaADuETn4rFxpteSviLR967mAiZ5zXp8MlLfcmn2jKD2EEiWvtzGE7Z12HF3+JYUTj7y5IMrbDT1oOpZzRT4VQq8NjpQj0dgJC9sJJ+ws8YFrsPu+Xb/Jxm3WXZyPXm6Tp7mIP8W3lteNlUfgtFlGSCG5LPDSCsFWtLOSGAEolC9R6MXvJuEnEpRK0glG/5UogMxNo0wqu+WvNvEa7h9lustF6gbGmNUcyJ/LJvl7i3OgqCXhOIDj3z910f4i/nglTeqDzCkQUEdGMkW6OodtBsrvYTfTOnaDGWhqG0fyjL9xrqfzkY2z8vJgVYmBNMxsrWReKNQnnRJC1yibqYIITWw5XKwUtQKNVwujLE0ia1RElxSaYYchsNFLhSvQJ1NR1oFZ9Ri6oz/VlFcoGJM2WC4xyPQhFn0xTauz8Z2pnxPFtqBxL6bOFfjrnhlMQ1qJtj1+sVuSFqD49rXxnWMCG4xcIXHYS//BvDoNxC6BQAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "../CommonFrontend/AcceptFlow/ReactComponents/FormComponents/Esignature.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../CommonFrontend/AcceptFlow/ReactComponents/FormComponents/Esignature.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VW7Y7bNhD8f0+xxaHAuSldyR/xWQcUBRL0AZoHMChyZdNHkQq5urNT3LsXomxZlBX3kPwSQO6sdmdnR5pu0mPq6q9VCv/eAeRcPG+drY1kwmrrMnjdKcKn5so6iY45LlXtM3jh7oGx9vCfcMaENeSs3mzSXK/2+zTdTi7ADNLqAN5qJeH+MeWzfLZInu7e7qbnCmZtBdfRn1fper6OYuchdodqu6MMBNfioS3oVMMX9Q1Z2VUySybwAdJQTsWlVGbLNBZ0bsNXXDRnB38p/jlES+UrzY8ZFBoPzQHXamuYIix9Bp4cktg15/vakyqOoQA0lEGTE1mO9IpoegTmlsiWY3TIqMUUpsRzHxrd8iqDZOx6s1FGKsHJuu8M8D5JHperfBxMPGdaeWLKGHQRqWmS/DramEBD6KJ8i4A8MXtFap/V/SQCLlug9YqUNRnw3Ftdt4JTxiMN2/44ADjUnNRLAHBfoSDmOCmbwWw6X8MfkEbwVYDHgfNpOr8K5O8q7EbPvTWoYiVtnZJ9Yq+1FGkMTayLPOY6pkeEy1claXeZ4Hmlkveo8DEZWfabrUY7A0kbbGvSymAGxprWPjphXs4Ka4gVvFT6mMFnXiprfgdRO38aaLj36htmMF9UYf3Oop4tZp8GniCywora91ykW4HFajlfSiEG8VmlucCd1fKk/Z8oSBa4whx+UWVlHXFDgYWGIzpmA3HJfo3nScy6SZydTFvHdsgb0juKMWjpvKMfZ00RvcwYMndSa4nu3Rf/L+t4uhcPTcvIzZtyJfc7jOz8Jz4ThAdiQfkXixnx3+bJpHIo2g6E1XVpbhrVYKEux8FWb/bbo247Jqw0Wa///vQ0brzNZSHm8yjNDv6E30KqkrutMt3840VWY4scW3Mveh+iO7W162pV0yfDFzTkR7SgfwRUxqBY16ZvTV1jQ2fsG4aKObY33J3s1bCuczX6sJwyaL7u/fU8/0OMDkqGy2ZQ79Gvr13BBV5evAsvPo3TtSN6/yew6onhNIXGOVk37OksxFePouB2eXquoEuQQG8A02fNmk0apB/S0Hv/1xDAGBcCKwrgMzF9I2rOux4WcQvUf0cLusoWI+q+vNdDF6tDGx41CtpsUGOJhhhDIwf/OCMCjbHKVDVF/5VnwF8lSsXBC4dogBsJDyU/sFNJi2VSHSYnoDxmxtLDfeWUoQkM/hEAkgxqI7FQBsOH/e0mSnwHBZBe5Xn7D2jqBJ+fCwAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var signatureContainer = '_1y1ruqp0';
export var card = _7a468({defaultClassName:'_1y1ruqp1',variantClassNames:{invalid:{true:'_1y1ruqp2'}},defaultVariants:{},compoundVariants:[]});
export var cardHeader = '_1y1ruqp3';
export var actions = '_1y1ruqp4';
export var tabPanel = _7a468({defaultClassName:'_1y1ruqp6',variantClassNames:{mode:{type:'_1y1ruqp7',draw:'_1y1ruqp8',upload:'_1y1ruqp9'}},defaultVariants:{},compoundVariants:[]});
export var inputWrapper = _7a468({defaultClassName:'_1y1ruqpa',variantClassNames:{isSimpleMode:{true:'_1y1ruqpb'}},defaultVariants:{},compoundVariants:[]});
export var input = _7a468({defaultClassName:'_1y1ruqpc',variantClassNames:{isSimpleMode:{true:'_1y1ruqpd'}},defaultVariants:{},compoundVariants:[]});
export var uploadWrapper = _7a468({defaultClassName:'_1y1ruqp5',variantClassNames:{hasUploaded:{true:'_1y1ruqpe'}},defaultVariants:{},compoundVariants:[]});
export var upload = _7a468({defaultClassName:'_1y1ruqpf',variantClassNames:{draggedOver:{true:'_1y1ruqpg'}},defaultVariants:{},compoundVariants:[]});
export var uploadDescription = '_1y1ruqph';
export var canvas = _7a468({defaultClassName:'_1y1ruqpi _1y1ruqp5',variantClassNames:{mode:{type:'_1y1ruqpj',draw:'_1y1ruqpk',upload:'_1y1ruqpl'},hasUploaded:{true:'_1y1ruqpm'}},defaultVariants:{},compoundVariants:[]});
export var error = '_1y1ruqpn';
export var errorIcon = '_1y1ruqpo';
export var errorText = '_1y1ruqpp';
export var simpleModeLabel = '_1y1ruqpq';
export var optionMenuItem = _7a468({defaultClassName:'',variantClassNames:{isSimpleMode:{true:'_1y1ruqpr',false:'_1y1ruqps'}},defaultVariants:{},compoundVariants:[]});
export var label = '_1y1ruqpt';
export var select = '_1y1ruqpu';
"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.qwilrPayPaymentsSettings = exports.multiEsignSettings = exports.esignSettings = exports.baseSettings = void 0;
var navigationModel = require("./Navigation");
var Types_1 = require("@CommonFrontendBackend/Accept/Types");
exports.baseSettings = {
    navigation: navigationModel,
    button: {
        custom: false,
        customLabel: "",
        customCompletionText: "Congratulations! The quote has been accepted.",
    },
    esignEnabled: false,
    accepters: {
        number: 1,
        enablePrefilledNames: false,
        prefilledNames: [],
    },
    payNow: {
        enabled: false,
        enableOptionalPayment: false,
        enablePartPayment: false,
    },
    email: {
        showTotal: true,
    },
    postAcceptance: (0, Types_1.createEmptyPostAcceptanceSettings)(),
    integrations: {},
};
exports.esignSettings = __assign(__assign({}, exports.baseSettings), { esignEnabled: true });
exports.multiEsignSettings = __assign(__assign({}, exports.esignSettings), { accepters: {
        number: 3,
        enablePrefilledNames: false,
        prefilledNames: [],
    } });
exports.qwilrPayPaymentsSettings = __assign(__assign({}, exports.baseSettings), { payNow: {
        enabled: true,
    } });

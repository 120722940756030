"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.StyleAccessType = exports.EXTENDED_REPORT_HISTORY_LIMIT = exports.REPORT_HISTORY_LIMIT = exports.ENGAGEMENT_ALGORITHM_HARD_LIMIT = exports.ADVANCED_ENABLED_ALGORITHMS_LIMIT = exports.BASIC_ENABLED_ALGORITHMS_LIMIT = exports.MAX_COLLECTION_LIMIT = exports.BASIC_COLLECTION_LIMIT = exports.NEW_DEFAULT_PAGE_COLLECTION = exports.QWILR_CREATED_PAGE_COLLECTION = exports.PageWithDisplay = exports.PageWithOption = exports.AUTHENTICATION_CONFIG = exports.CurrencyCode = exports.AuthenticationMethod = exports.AuthenticationStatus = void 0;
var AuthenticationStatus;
(function (AuthenticationStatus) {
    AuthenticationStatus["Enabled"] = "enabled";
    AuthenticationStatus["Disabled"] = "disabled";
    AuthenticationStatus["AdminOnly"] = "adminOnly";
})(AuthenticationStatus || (exports.AuthenticationStatus = AuthenticationStatus = {}));
var AuthenticationMethod;
(function (AuthenticationMethod) {
    AuthenticationMethod["Google"] = "google";
    AuthenticationMethod["Salesforce"] = "salesforce";
    AuthenticationMethod["Hubspot"] = "hubspot";
    AuthenticationMethod["Microsoft"] = "microsoft";
    AuthenticationMethod["Email"] = "email";
    AuthenticationMethod["Stanford"] = "stanford";
    AuthenticationMethod["Intuit"] = "intuit";
    AuthenticationMethod["OneTimeToken"] = "oneTimeToken";
    AuthenticationMethod["InviteToken"] = "inviteToken";
})(AuthenticationMethod || (exports.AuthenticationMethod = AuthenticationMethod = {}));
var CurrencyCode;
(function (CurrencyCode) {
    CurrencyCode["USD"] = "USD";
    CurrencyCode["EUR"] = "EUR";
    CurrencyCode["JPY"] = "JPY";
    CurrencyCode["GBP"] = "GBP";
    CurrencyCode["AUD"] = "AUD";
    CurrencyCode["CHF"] = "CHF";
    CurrencyCode["CAD"] = "CAD";
    CurrencyCode["MXN"] = "MXN";
    CurrencyCode["CNY"] = "CNY";
    CurrencyCode["NZD"] = "NZD";
    CurrencyCode["SEK"] = "SEK";
    CurrencyCode["RUB"] = "RUB";
    CurrencyCode["AFN"] = "AFN";
    CurrencyCode["ALL"] = "ALL";
    CurrencyCode["DZD"] = "DZD";
    CurrencyCode["AOA"] = "AOA";
    CurrencyCode["ANG"] = "ANG";
    CurrencyCode["ARS"] = "ARS";
    CurrencyCode["AMD"] = "AMD";
    CurrencyCode["AZN"] = "AZN";
    CurrencyCode["BHD"] = "BHD";
    CurrencyCode["BDT"] = "BDT";
    CurrencyCode["BSD"] = "BSD";
    CurrencyCode["BYR"] = "BYR";
    CurrencyCode["BZD"] = "BZD";
    CurrencyCode["BOB"] = "BOB";
    CurrencyCode["BAM"] = "BAM";
    CurrencyCode["BWP"] = "BWP";
    CurrencyCode["BRL"] = "BRL";
    CurrencyCode["BND"] = "BND";
    CurrencyCode["BGN"] = "BGN";
    CurrencyCode["BIF"] = "BIF";
    CurrencyCode["KHR"] = "KHR";
    CurrencyCode["CVE"] = "CVE";
    CurrencyCode["XAF"] = "XAF";
    CurrencyCode["CLP"] = "CLP";
    CurrencyCode["COP"] = "COP";
    CurrencyCode["KMF"] = "KMF";
    CurrencyCode["CRC"] = "CRC";
    CurrencyCode["HRK"] = "HRK";
    CurrencyCode["CZK"] = "CZK";
    CurrencyCode["DJF"] = "DJF";
    CurrencyCode["DOP"] = "DOP";
    CurrencyCode["CDF"] = "CDF";
    CurrencyCode["EGP"] = "EGP";
    CurrencyCode["ERN"] = "ERN";
    CurrencyCode["ETB"] = "ETB";
    CurrencyCode["GEL"] = "GEL";
    CurrencyCode["GHS"] = "GHS";
    CurrencyCode["DKK"] = "DKK";
    CurrencyCode["GTQ"] = "GTQ";
    CurrencyCode["GNF"] = "GNF";
    CurrencyCode["HNL"] = "HNL";
    CurrencyCode["HKD"] = "HKD";
    CurrencyCode["HUF"] = "HUF";
    CurrencyCode["ISK"] = "ISK";
    CurrencyCode["INR"] = "INR";
    CurrencyCode["IDR"] = "IDR";
    CurrencyCode["IRR"] = "IRR";
    CurrencyCode["IQD"] = "IQD";
    CurrencyCode["ILS"] = "ILS";
    CurrencyCode["JMD"] = "JMD";
    CurrencyCode["JOD"] = "JOD";
    CurrencyCode["KZT"] = "KZT";
    CurrencyCode["KES"] = "KES";
    CurrencyCode["KWD"] = "KWD";
    CurrencyCode["LBP"] = "LBP";
    CurrencyCode["LYD"] = "LYD";
    CurrencyCode["MOP"] = "MOP";
    CurrencyCode["MKD"] = "MKD";
    CurrencyCode["MGA"] = "MGA";
    CurrencyCode["MYR"] = "MYR";
    CurrencyCode["MUR"] = "MUR";
    CurrencyCode["MDL"] = "MDL";
    CurrencyCode["MAD"] = "MAD";
    CurrencyCode["MZN"] = "MZN";
    CurrencyCode["MMK"] = "MMK";
    CurrencyCode["NAD"] = "NAD";
    CurrencyCode["NPR"] = "NPR";
    CurrencyCode["NIO"] = "NIO";
    CurrencyCode["XOF"] = "XOF";
    CurrencyCode["NGN"] = "NGN";
    CurrencyCode["NOK"] = "NOK";
    CurrencyCode["OMR"] = "OMR";
    CurrencyCode["PKR"] = "PKR";
    CurrencyCode["PAB"] = "PAB";
    CurrencyCode["PGK"] = "PGK";
    CurrencyCode["PYG"] = "PYG";
    CurrencyCode["PEN"] = "PEN";
    CurrencyCode["PHP"] = "PHP";
    CurrencyCode["PLN"] = "PLN";
    CurrencyCode["QAR"] = "QAR";
    CurrencyCode["RON"] = "RON";
    CurrencyCode["RWF"] = "RWF";
    CurrencyCode["SAR"] = "SAR";
    CurrencyCode["RSD"] = "RSD";
    CurrencyCode["SGD"] = "SGD";
    CurrencyCode["SOS"] = "SOS";
    CurrencyCode["ZAR"] = "ZAR";
    CurrencyCode["KRW"] = "KRW";
    CurrencyCode["LKR"] = "LKR";
    CurrencyCode["SDG"] = "SDG";
    CurrencyCode["SYP"] = "SYP";
    CurrencyCode["TWD"] = "TWD";
    CurrencyCode["TZS"] = "TZS";
    CurrencyCode["THB"] = "THB";
    CurrencyCode["TOP"] = "TOP";
    CurrencyCode["TTD"] = "TTD";
    CurrencyCode["TND"] = "TND";
    CurrencyCode["TRY"] = "TRY";
    CurrencyCode["UGX"] = "UGX";
    CurrencyCode["UAH"] = "UAH";
    CurrencyCode["AED"] = "AED";
    CurrencyCode["UYU"] = "UYU";
    CurrencyCode["UZS"] = "UZS";
    CurrencyCode["VEF"] = "VEF";
    CurrencyCode["VND"] = "VND";
    CurrencyCode["XPF"] = "XPF";
    CurrencyCode["YER"] = "YER";
})(CurrencyCode || (exports.CurrencyCode = CurrencyCode = {}));
exports.AUTHENTICATION_CONFIG = {
    google: {
        internalName: AuthenticationMethod.Google,
        displayName: "Google",
        allowedValues: [AuthenticationStatus.Enabled, AuthenticationStatus.Disabled],
    },
    salesforce: {
        internalName: AuthenticationMethod.Salesforce,
        displayName: "Salesforce",
        allowedValues: [AuthenticationStatus.Enabled, AuthenticationStatus.Disabled],
    },
    hubspot: {
        internalName: AuthenticationMethod.Hubspot,
        displayName: "HubSpot",
        allowedValues: [AuthenticationStatus.Enabled, AuthenticationStatus.Disabled],
    },
    microsoft: {
        internalName: AuthenticationMethod.Microsoft,
        displayName: "Microsoft",
        allowedValues: [AuthenticationStatus.Enabled, AuthenticationStatus.Disabled],
    },
    email: {
        internalName: AuthenticationMethod.Email,
        displayName: "Email",
        allowedValues: [AuthenticationStatus.Enabled, AuthenticationStatus.Disabled, AuthenticationStatus.AdminOnly],
    },
};
var PageWithOption;
(function (PageWithOption) {
    PageWithOption["AcceptBlock"] = "acceptBlock";
    PageWithOption["QuoteBlock"] = "quoteBlock";
    PageWithOption["AcceptOrQuoteBlock"] = "acceptOrQuoteBlock";
    PageWithOption["Value"] = "value";
    PageWithOption["AnyContent"] = "anyContent";
})(PageWithOption || (exports.PageWithOption = PageWithOption = {}));
exports.PageWithDisplay = (_a = {},
    _a[PageWithOption.AcceptBlock] = "Accept Block",
    _a[PageWithOption.QuoteBlock] = "Quote Block",
    _a[PageWithOption.AcceptOrQuoteBlock] = "Accept or quote block",
    _a[PageWithOption.Value] = "Value",
    _a[PageWithOption.AnyContent] = "Any content",
    _a);
exports.QWILR_CREATED_PAGE_COLLECTION = {
    name: "Proposals",
    pageFilter: {
        templateIds: [],
        tags: [],
        pageWith: PageWithOption.AcceptOrQuoteBlock,
    },
    isDefault: false,
    createdByQwilr: true,
};
exports.NEW_DEFAULT_PAGE_COLLECTION = {
    name: "Filter name",
    pageFilter: {
        templateIds: [],
        tags: [],
        pageWith: PageWithOption.AcceptOrQuoteBlock,
    },
    isDefault: false,
    createdByQwilr: false,
};
exports.BASIC_COLLECTION_LIMIT = 2;
exports.MAX_COLLECTION_LIMIT = 8;
exports.BASIC_ENABLED_ALGORITHMS_LIMIT = 3;
exports.ADVANCED_ENABLED_ALGORITHMS_LIMIT = 10;
exports.ENGAGEMENT_ALGORITHM_HARD_LIMIT = 20;
exports.REPORT_HISTORY_LIMIT = 120;
exports.EXTENDED_REPORT_HISTORY_LIMIT = 2000;
var StyleAccessType;
(function (StyleAccessType) {
    StyleAccessType["Block"] = "block";
    StyleAccessType["Text"] = "text";
    StyleAccessType["Motion"] = "motion";
    StyleAccessType["StyleLibrary"] = "styleLibrary";
    StyleAccessType["BlockCustomColors"] = "blockCustomColors";
    StyleAccessType["Toolbar"] = "toolbar";
    StyleAccessType["ToolbarTable"] = "toolbarTable";
    StyleAccessType["ToolbarQuote"] = "toolbarQuote";
    StyleAccessType["ToolbarAccept"] = "toolbarAccept";
    StyleAccessType["CSS"] = "css";
    StyleAccessType["Accordion"] = "accordion";
    StyleAccessType["ContentCustomColors"] = "contentCustomColors";
})(StyleAccessType || (exports.StyleAccessType = StyleAccessType = {}));

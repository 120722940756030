"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.mergePaymentSettings = void 0;
var mergePaymentSettings = function (acceptBlockSettings, shouldUsePaymentSettings, paymentSettings, qwilrPayEnabled) {
    if (qwilrPayEnabled === void 0) { qwilrPayEnabled = true; }
    return __assign(__assign({}, acceptBlockSettings), (shouldUsePaymentSettings &&
        paymentSettings && {
        payNow: {
            enabled: paymentSettings.enabled && qwilrPayEnabled,
            enableOptionalPayment: !paymentSettings.requireOnAccept,
            enablePartPayment: paymentSettings.partialPaymentConfig.enabled,
            partPaymentType: paymentSettings.partialPaymentConfig.kind,
            partPaymentAmount: paymentSettings.partialPaymentConfig.amount,
            payLaterButtonText: paymentSettings.payLaterButtonText,
        },
    }));
};
exports.mergePaymentSettings = mergePaymentSettings;

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertCaseKeys = exports.calculateQwilrAppDayNumber = exports.identifyUsers = exports.buildAccountEventProperties = exports.PaymentsEventName = void 0;
var Util_1 = require("@CommonFrontendBackend/Permissions/Util");
var PaymentsEventName;
(function (PaymentsEventName) {
    PaymentsEventName["PaymentsDashboardOpened"] = "Payments Dashboard Opened";
    PaymentsEventName["CurrentGatewayChanged"] = "Current Gateway Changed";
    PaymentsEventName["SettingsModalOpened"] = "Payment Settings Modal Opened";
    PaymentsEventName["QwilrPayWidgetOpened"] = "QwilrPay Widget Opened";
    PaymentsEventName["OnboardingModalOpened"] = "Payments Onboarding Modal Opened";
    PaymentsEventName["OnboardingModalClosed"] = "Payments Onboarding Modal Closed";
    PaymentsEventName["CountrySelected"] = "Payments Onboarding Country Selected";
    PaymentsEventName["PaymentGatewayCreated"] = "Payments Gateway Created";
    PaymentsEventName["PaymentGatewayOnboarded"] = "Payments Gateway Onboarded";
    PaymentsEventName["PaymentGatewayUpdated"] = "Payments Gateway Updated";
    PaymentsEventName["PaymentGatewayDeleted"] = "Payments Gateway Deleted";
    PaymentsEventName["PaymentPermissionsUpdated"] = "Payments Permissions Updated";
    PaymentsEventName["PaymentsEnabled"] = "Payments Enabled";
    PaymentsEventName["PaymentsDisabled"] = "Payments Disabled";
})(PaymentsEventName || (exports.PaymentsEventName = PaymentsEventName = {}));
function buildAccountEventProperties(account, plan) {
    var _a, _b, _c, _d, _e;
    return {
        account_plan_level: (_a = plan.internalName) === null || _a === void 0 ? void 0 : _a.split("_")[0],
        account_plan_name: plan.internalName,
        account_is_trial_plan: plan.displayName === "Trial",
        account_is_trial_active: account.status == "open" && new Date((_b = account.timeTrial) === null || _b === void 0 ? void 0 : _b.endDate).getTime() >= Date.now(),
        account_seats_plan: (_c = plan.seats) === null || _c === void 0 ? void 0 : _c.included,
        account_seats_additional: account.additionalSeats,
        account_seats_total: ((_d = plan.seats) === null || _d === void 0 ? void 0 : _d.included) + account.additionalSeats,
        account_users_enabled: (_e = account.users) === null || _e === void 0 ? void 0 : _e.length,
        account_plan_interval: plan.unit,
        stripe_plan_price: plan.price,
        has_qwilr_account: true,
    };
}
exports.buildAccountEventProperties = buildAccountEventProperties;
function identifyUsers(users, userList, telemetry, accountEventProps) {
    users
        .find({
        _id: { $in: userList },
    }, {
        select: "_id account email",
    })
        .then(function (matchedUsers) {
        var e_1, _a;
        try {
            for (var matchedUsers_1 = __values(matchedUsers), matchedUsers_1_1 = matchedUsers_1.next(); !matchedUsers_1_1.done; matchedUsers_1_1 = matchedUsers_1.next()) {
                var user = matchedUsers_1_1.value;
                telemetry.set(user, __assign({ user_role: (0, Util_1.isAdmin)(user) ? "admin" : "creator" }, accountEventProps));
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (matchedUsers_1_1 && !matchedUsers_1_1.done && (_a = matchedUsers_1.return)) _a.call(matchedUsers_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    });
}
exports.identifyUsers = identifyUsers;
function calculateQwilrAppDayNumber(createdAt) {
    var today = new Date();
    var millisecondsPerDay = 24 * 60 * 60 * 1000;
    var daysDiffInMilliseconds = today.getTime() - new Date(createdAt).getTime();
    return Math.floor(daysDiffInMilliseconds / millisecondsPerDay) + 1;
}
exports.calculateQwilrAppDayNumber = calculateQwilrAppDayNumber;
function convertCaseKeys(obj, toCase) {
    var camelObj = {};
    var toTargetCase = toCase === "camel"
        ? function (key) { return key.replace(/_([a-z])/g, function (_, match) { return match.toUpperCase(); }); }
        : function (key) { return key.replace(/[A-Z]/g, function (match) { return "_".concat(match.toLowerCase()); }); };
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
            var camelKey = toTargetCase(key);
            camelObj[camelKey] = obj[key];
        }
    }
    return camelObj;
}
exports.convertCaseKeys = convertCaseKeys;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocExtractionFailureReason = exports.DocExtractionStatus = void 0;
var DocExtractionStatus;
(function (DocExtractionStatus) {
    DocExtractionStatus["Uploaded"] = "uploaded";
    DocExtractionStatus["Generating"] = "generating";
    DocExtractionStatus["Complete"] = "complete";
    DocExtractionStatus["CompletePartialFailure"] = "complete-partial-failure";
    DocExtractionStatus["Failed"] = "failed";
})(DocExtractionStatus || (exports.DocExtractionStatus = DocExtractionStatus = {}));
var DocExtractionFailureReason;
(function (DocExtractionFailureReason) {
    DocExtractionFailureReason["UnexpectedErrorFromLambda"] = "unexpected-error-from-lambda";
    DocExtractionFailureReason["AllChildrenHitFailure"] = "all-children-hit-failure";
    DocExtractionFailureReason["NoProjectIdReturnedFromDocGeneration"] = "no-project-id-returned-from-doc-generation";
    DocExtractionFailureReason["InternalApiRequestFailed"] = "internal-api-request-failed";
    DocExtractionFailureReason["UnexpectedErrorFromBuildPageFromExtract"] = "unexpected-error-from-build-page-from-extract";
})(DocExtractionFailureReason || (exports.DocExtractionFailureReason = DocExtractionFailureReason = {}));

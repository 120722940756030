"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createButtonToken = exports.isButtonToken = void 0;
var CommonUtils_1 = require("@CommonFrontendBackend/Utils/CommonUtils");
var isButtonToken = function (token) {
    return token.subType === "button";
};
exports.isButtonToken = isButtonToken;
var createButtonToken = function (_a) {
    var text = _a.text, alignment = _a.alignment, style = _a.style, href = _a.href;
    return __assign({ type: "block", subType: "button", text: text, alignment: alignment, href: href }, (0, CommonUtils_1.includeIf)(!!style, { style: style }));
};
exports.createButtonToken = createButtonToken;

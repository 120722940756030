"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.normalizeStyle = void 0;
var CommonWidgetRegistry_1 = require("@CommonFrontend/WidgetRegistry/CommonWidgetRegistry");
var GetTraitConfig_1 = require("@CommonFrontend/WidgetRegistry/Utils/GetTraitConfig");
var assertUnreachable_1 = require("@CommonFrontendBackend/Utils/assertUnreachable");
var lodash_1 = require("lodash");
var BlockTypes_1 = require("@CommonFrontendBackend/BlockModels/BlockTypes");
var zod_1 = require("zod");
var CommonUtils_1 = require("@CommonFrontendBackend/Utils/CommonUtils");
var passthroughProperties = ["blockType"];
var nonRegistryProperties = [
    {
        key: "css",
        default: "",
    },
    {
        key: "blockSpacing",
        default: "medium",
    },
    {
        key: "blockWidth",
        default: "medium",
    },
    {
        blockType: BlockTypes_1.BlockType.Text,
        key: "blockHeight",
        default: "none",
    },
    {
        key: "animation.animationOption",
        default: "none",
    },
    {
        key: "animation.animationType",
        default: "fade",
        forceDefaultIf: {
            key: "animation.animationOption",
            equals: "none",
        },
    },
    {
        key: "animation.direction",
        default: "down",
        forceDefaultIf: {
            key: "animation.animationOption",
            equals: "none",
        },
    },
    {
        key: "animation.speed",
        default: "medium",
        forceDefaultIf: {
            key: "animation.animationOption",
            equals: "none",
        },
    },
    {
        key: "background.themeIndex",
        default: -1,
    },
    {
        key: "background.abs",
        default: undefined,
        forceDefaultIf: {
            key: "background.themeIndex",
            notEquals: null,
        },
    },
    {
        blockType: BlockTypes_1.BlockType.Text,
        key: "background.tintColor",
        default: { themeIndex: -3, opacity: 0.25 },
        forceDefaultIf: {
            key: "background.type",
            equals: "color",
        },
    },
    {
        blockType: BlockTypes_1.BlockType.Text,
        key: "background.tintBlendMode",
        default: "normal",
        forceDefaultIf: {
            key: "background.type",
            equals: "color",
        },
    },
    {
        blockType: BlockTypes_1.BlockType.Text,
        key: "background.card.enabled",
        default: false,
    },
    {
        blockType: BlockTypes_1.BlockType.Text,
        key: "background.card.color",
        default: { themeIndex: -3, opacity: 0.25 },
        forceDefaultIf: {
            key: "background.card.enabled",
            equals: false,
        },
    },
    {
        blockType: BlockTypes_1.BlockType.Text,
        key: "background.type",
        default: "color",
    },
    {
        blockType: BlockTypes_1.BlockType.Text,
        key: "background.url",
        default: undefined,
        forceDefaultIf: {
            key: "background.type",
            equals: "color",
        },
    },
    {
        blockType: BlockTypes_1.BlockType.Text,
        key: "background.videoThumbnail",
        default: undefined,
        forceDefaultIf: {
            key: "background.type",
            equals: "color",
        },
    },
    {
        blockType: BlockTypes_1.BlockType.Text,
        key: "background.blur",
        default: 0,
        forceDefaultIf: {
            key: "background.type",
            equals: "color",
        },
    },
    {
        key: "link.color",
        default: { themeIndex: -3 },
    },
    {
        key: "link.underline.on",
        default: true,
    },
    {
        key: "link.underline.color",
        default: { themeIndex: -3 },
        forceDefaultIf: {
            key: "link.underline.on",
            equals: false,
        },
    },
    {
        blockType: BlockTypes_1.BlockType.Quote,
        key: "quoteBackgroundOn",
        default: true,
    },
    {
        blockType: BlockTypes_1.BlockType.Quote,
        key: "headerSection.on",
        default: true,
        forceDefaultIf: {
            key: "quoteBackgroundOn",
            equals: true,
        },
    },
    {
        blockType: BlockTypes_1.BlockType.Quote,
        key: "headerSection.color",
        default: { themeIndex: 0 },
        forceDefaultIf: {
            key: "headerSection.on",
            equals: false,
        },
    },
    {
        blockType: BlockTypes_1.BlockType.Quote,
        key: "uiHighlightColor",
        default: { themeIndex: 0 },
    },
    {
        blockType: BlockTypes_1.BlockType.Accept,
        key: "acceptButton.color",
        default: { themeIndex: 0 },
    },
    {
        blockType: BlockTypes_1.BlockType.Accept,
        key: "acceptButton.alignment",
        default: "center",
    },
];
var forcedRegistryDefaults = [
    {
        key: "accordion.expandButtonText",
        forceDefaultIf: {
            key: "accordion.buttonStyle",
            notEquals: "label",
        },
    },
    {
        key: "accordion.closeButtonText",
        forceDefaultIf: {
            key: "accordion.buttonStyle",
            notEquals: "label",
        },
    },
    {
        key: "accordion.headerBackground.on",
        forceDefaultIf: {
            key: "accordion.cardStyle",
            notEquals: "simple",
        },
    },
    {
        key: "accordion.headerBackground.color",
        forceDefaultIf: {
            key: "accordion.headerBackground.on",
            equals: false,
        },
    },
    {
        key: "accordion.imageHeight",
        forceDefaultIf: {
            key: "accordion.cardStyle",
            equals: "simple",
        },
    },
    {
        key: "accordion.bodyBackground.on",
        forceDefaultIf: {
            key: "accordion.cardStyle",
            notEquals: "simple",
        },
    },
    {
        key: "accordion.buttonAlignment",
        forceDefaultIf: {
            key: "accordion.cardStyle",
            notEquals: "simple",
        },
    },
    {
        key: "accordion.tint",
        forceDefaultIf: {
            key: "accordion.cardStyle",
            equals: "simple",
        },
    },
    {
        key: "table.background.color",
        forceDefaultIf: {
            key: "table.background.on",
            equals: false,
        },
    },
    {
        key: "table.header.color",
        forceDefaultIf: {
            key: "table.header.on",
            equals: false,
        },
    },
];
var shouldForceDefault = function (property, style) {
    if (property === null || property === void 0 ? void 0 : property.forceDefaultIf) {
        var dependantValue = getValue(property.forceDefaultIf.key, style);
        if ("equals" in property.forceDefaultIf) {
            return dependantValue === property.forceDefaultIf.equals;
        }
        else if ("notEquals" in property.forceDefaultIf) {
            return dependantValue !== property.forceDefaultIf.notEquals;
        }
    }
    return false;
};
var simplifyColor = function (maybeColor) {
    var color = zod_1.z
        .object({
        themeIndex: zod_1.z.number().nullish(),
        abs: zod_1.z.string().optional(),
        value: zod_1.z.string().optional(),
        opacity: zod_1.z.number().optional(),
    })
        .parse(maybeColor);
    var result = "opacity" in color && color.opacity != null ? { opacity: color.opacity } : {};
    if ("themeIndex" in color && color.themeIndex != null) {
        return __assign(__assign({}, result), { themeIndex: color.themeIndex });
    }
    else if ("value" in color && color.value != null) {
        return __assign(__assign({}, result), { abs: color.value });
    }
    else if ("abs" in color && color.abs != null) {
        return __assign(__assign({}, result), { abs: color.abs });
    }
    throw new Error("Unable to simplify color");
};
var splitKey = function (key) {
    var _a;
    var parts = key.split(".");
    return [(_a = parts[0]) !== null && _a !== void 0 ? _a : "", parts.slice(1).join(".")];
};
var getValue = function (key, style) {
    var _a, _b;
    var nonRegistryProperty = nonRegistryProperties.find(function (p) { return (!p.blockType || p.blockType === style.blockType) && p.key === key; });
    var _c = __read(splitKey(key), 2), styleKey = _c[0], path = _c[1];
    var registryProperty = CommonWidgetRegistry_1.CommonWidgetRegistryConfigs.reduce(function (acc, config) {
        var styleableTrait = (0, GetTraitConfig_1.getTraitConfig)({ widgetType: config.type, traitName: "styleable" });
        if ((styleableTrait === null || styleableTrait === void 0 ? void 0 : styleableTrait.styleKey) === styleKey) {
            return styleableTrait === null || styleableTrait === void 0 ? void 0 : styleableTrait.properties.find(function (p) { return p.path === path; });
        }
        return acc;
    }, undefined);
    if (nonRegistryProperty) {
        return shouldForceDefault(nonRegistryProperty, style)
            ? nonRegistryProperty.default
            : (0, lodash_1.get)(style, nonRegistryProperty.key, nonRegistryProperty.default);
    }
    else if (registryProperty) {
        var forcedRegistryDefault = forcedRegistryDefaults.find(function (d) { return d.key === key; });
        var forceDefault = shouldForceDefault(forcedRegistryDefault, style);
        if (registryProperty.type === "alignable" ||
            registryProperty.type === "boolean" ||
            registryProperty.type === "enumerable" ||
            registryProperty.type === "numerical" ||
            registryProperty.type === "textLabel" ||
            registryProperty.type === "fontFamily") {
            return forceDefault ? registryProperty === null || registryProperty === void 0 ? void 0 : registryProperty.defaultValue : (0, lodash_1.get)(style, key, registryProperty === null || registryProperty === void 0 ? void 0 : registryProperty.defaultValue);
        }
        if (registryProperty.type === "color") {
            var defaultValue = void 0;
            if (registryProperty.defaultValue === "DERIVED_FROM_HEADING_HACK") {
                defaultValue = getValue("h1.color", style);
            }
            else if (registryProperty.defaultValue === "DERIVED_FROM_PARAGRAPH_HACK") {
                defaultValue = getValue("p.color", style);
            }
            else {
                defaultValue = __assign(__assign({}, (0, CommonUtils_1.includeIf)(((_a = registryProperty.opacity) === null || _a === void 0 ? void 0 : _a.defaultValue) != null, {
                    opacity: (_b = registryProperty.opacity) === null || _b === void 0 ? void 0 : _b.defaultValue,
                })), registryProperty.defaultValue);
            }
            var value = (0, lodash_1.get)(style, key, defaultValue);
            return simplifyColor(forceDefault ? defaultValue : value);
        }
        if (registryProperty.type === "fontSize") {
            var defaultValue = void 0;
            if (registryProperty.useParagraphBlockFontSize) {
                defaultValue = getValue("p.size", style);
            }
            else {
                defaultValue = registryProperty.defaultValue;
            }
            return forceDefault ? defaultValue : (0, lodash_1.get)(style, key, defaultValue);
        }
        return (0, assertUnreachable_1.assertUnreachable)(registryProperty);
    }
    return undefined;
};
var normalizeStyle = function (style) {
    var e_1, _a, e_2, _b, e_3, _c;
    var result = (0, lodash_1.pick)(style, passthroughProperties);
    try {
        for (var CommonWidgetRegistryConfigs_1 = __values(CommonWidgetRegistry_1.CommonWidgetRegistryConfigs), CommonWidgetRegistryConfigs_1_1 = CommonWidgetRegistryConfigs_1.next(); !CommonWidgetRegistryConfigs_1_1.done; CommonWidgetRegistryConfigs_1_1 = CommonWidgetRegistryConfigs_1.next()) {
            var config = CommonWidgetRegistryConfigs_1_1.value;
            var styleableTrait = (0, GetTraitConfig_1.getTraitConfig)({ widgetType: config.type, traitName: "styleable" });
            if (styleableTrait) {
                try {
                    for (var _d = (e_2 = void 0, __values(styleableTrait.properties)), _e = _d.next(); !_e.done; _e = _d.next()) {
                        var property = _e.value;
                        var path = "".concat(styleableTrait.styleKey, ".").concat(property.path);
                        var value = getValue(path, style);
                        if (value != null) {
                            (0, lodash_1.set)(result, path, value);
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_e && !_e.done && (_b = _d.return)) _b.call(_d);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (CommonWidgetRegistryConfigs_1_1 && !CommonWidgetRegistryConfigs_1_1.done && (_a = CommonWidgetRegistryConfigs_1.return)) _a.call(CommonWidgetRegistryConfigs_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    try {
        for (var nonRegistryProperties_1 = __values(nonRegistryProperties), nonRegistryProperties_1_1 = nonRegistryProperties_1.next(); !nonRegistryProperties_1_1.done; nonRegistryProperties_1_1 = nonRegistryProperties_1.next()) {
            var property = nonRegistryProperties_1_1.value;
            if (!!property.blockType && property.blockType !== style.blockType)
                continue;
            var value = getValue(property.key, style);
            if (value != null) {
                (0, lodash_1.set)(result, property.key, value);
            }
        }
    }
    catch (e_3_1) { e_3 = { error: e_3_1 }; }
    finally {
        try {
            if (nonRegistryProperties_1_1 && !nonRegistryProperties_1_1.done && (_c = nonRegistryProperties_1.return)) _c.call(nonRegistryProperties_1);
        }
        finally { if (e_3) throw e_3.error; }
    }
    return result;
};
exports.normalizeStyle = normalizeStyle;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createHeading4Token = exports.Heading4Config = void 0;
var Heading4_css_1 = require("ContentTokens/SharedWidgetComponents/Text/Heading4.css");
var GenericText_1 = require("./GenericText");
var commonTypes_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/BlockConfig/commonTypes");
exports.Heading4Config = (0, GenericText_1.makeGenericTextConfig)("heading4", "h4", { vars: Heading4_css_1.vars, widget: Heading4_css_1.h4Widget, spacingShim: Heading4_css_1.h4SpacingShim }, {
    textColor: "DERIVED_FROM_HEADING_HACK",
    fontSize: 24,
    lineHeight: Heading4_css_1.defaultLineHeight,
    blockSpacing: Heading4_css_1.defaultBlockSpacingEm,
    brandFontRef: commonTypes_1.TextElementType.H2,
});
var createHeading4Token = function () { return ({
    type: "block",
    subType: "heading4",
}); };
exports.createHeading4Token = createHeading4Token;

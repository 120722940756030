"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlockPreset = exports.BlockType = void 0;
var BlockType;
(function (BlockType) {
    BlockType["Video"] = "Video";
    BlockType["Splash"] = "Splash";
    BlockType["Text"] = "Text";
    BlockType["Source"] = "Source";
    BlockType["Accept"] = "Accept";
    BlockType["Quote"] = "QuoteV2";
    BlockType["OldQuote"] = "Quote";
    BlockType["Agreement"] = "Agreement";
})(BlockType || (exports.BlockType = BlockType = {}));
var BlockPreset;
(function (BlockPreset) {
    BlockPreset["QuoteTableOnly"] = "QuoteTableOnly";
    BlockPreset["QuotePlansOnly"] = "QuotePlansOnly";
    BlockPreset["QuoteCombinedPlansAndTable"] = "QuoteCombinedPlansAndTable";
    BlockPreset["AcceptClickTo"] = "AcceptClickTo";
    BlockPreset["AcceptEsign"] = "AcceptEsign";
    BlockPreset["AcceptMultiEsign"] = "AcceptMultiEsign";
    BlockPreset["Payments"] = "Payments";
})(BlockPreset || (exports.BlockPreset = BlockPreset = {}));

import "../CommonFrontend/AcceptFlow/ReactComponents/FormComponents/PayNow.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../CommonFrontend/AcceptFlow/ReactComponents/FormComponents/PayNow.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5VUTW+cMBC976+YqqqUlTDCbAmBvbZ/oD30GA3GyzoxNvJHQlv1v1cYlngVpG2PvJl57814hlT9qpiVGfzeAQzYtkJ1NdAqLYYRXtDcEWIHZEJ1pH98pI0sn54o7ffHHUCD7Lkz2quWMC21qeH1LByfQswbOwGOj+64+7NLZxkaZBptWm6IwVZ4W0N2XLGrD9Jo53RfQz6MYLUU7WIoiJEzx8nsaooHT0sLRCgpFA98q3oe1J1BZYUTWtWL0ELbeoMTTOxbo34PHC0n2rvje+dz3Qx+CxhhWjmj5RtDt4/bo2srpmvwjuZVAvQ+T4CWDwlkaV7sY8P1Wb9wEw9tGfR29XVx+t0ZMfCvkvdcOUJOmnm7wfXxgWLe5FHpIWRdwl9KWh0q+CD6QRuHKn7QzxuZ26sRHOe0SCAvEijK0O1+Y6hF9ikSKILASfKxBhrh9wHv0XRCEclPrgb0Tk98084RlKJTNRjRnWO/ZShrhR0k/qxBacWjaBV3Mz8uMsYHRwJnCMQjxv/Mb+IrW1zfuLFLcmjkRnakI7Hhch6dVo688rh8gn4EhBjeeYlm5Tlk1zxCDd5d/xv+zQKbF+3dGsQXbL05IeMrQxd3fHMym7d4yR6jG36efP0F37WDg+gEAAA=\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var stripeElementWrapper = _7a468({defaultClassName:'nz9csl0',variantClassNames:{styleMode:{simple:'nz9csl1',standard:'nz9csl2'}},defaultVariants:{},compoundVariants:[]});
export var stripeInputErrorText = 'nz9csl3';
export var stripeInputErrorIcon = 'nz9csl4';
export var splitElementWrapper = 'nz9csl5';
export var totalRightSide = 'nz9csl6';
export var payNowFormWrapper = _7a468({defaultClassName:'',variantClassNames:{readyToView:{false:'nz9csl7',true:'nz9csl8'}},defaultVariants:{},compoundVariants:[]});
export var previewAlert = 'nz9csl9';
export var label = 'nz9csla';
export var billingDetails = 'nz9cslb';
export var paymentElementWrapper = 'nz9cslc';
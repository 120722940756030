(function () {
  var COMBINED_TYPE,
    DiscountAdapter,
    QuoteAdapter,
    SectionAdapter,
    TaxService,
    _,
    calculateQuote,
    createDiscountModel,
    createSectionModel;

  _ = require("lodash");

  createSectionModel = require("../Models/Section").BaseSectionModel;

  createDiscountModel = require("../Models/Discount");

  DiscountAdapter = require("./DiscountAdapter");

  calculateQuote = require("../Calculator/CalculateQuote").calculateQuote;

  TaxService = require("../TaxService")["default"];

  SectionAdapter = require("./SectionAdapter");

  COMBINED_TYPE = "combined";

  module.exports = QuoteAdapter = (function () {
    function QuoteAdapter(
      block,
      accountQuoteSettings,
      variableService,
      discountService,
      labelService,
      billingScheduleOrchestrator,
      telemetry,
    ) {
      var getViewSettings, init, rebuildSectionAdapters, sectionAdapters;
      sectionAdapters = [];
      init = (function (_this) {
        return function () {
          if (block.sections == null || block.sections.length === 0) {
            return _this.createSection(0);
          } else {
            return rebuildSectionAdapters();
          }
        };
      })(this);
      this.getSectionAdapters = function () {
        if (this.hasMismatchedLengths()) {
          rebuildSectionAdapters();
        }
        return sectionAdapters;
      };
      rebuildSectionAdapters = (function (_this) {
        return function () {
          var i, len, ref, results, sectionModel;
          sectionAdapters = [];
          ref = block.sections;
          results = [];
          for (i = 0, len = ref.length; i < len; i++) {
            sectionModel = ref[i];
            results.push(sectionAdapters.push(new SectionAdapter(sectionModel, _this.labelForField, variableService)));
          }
          return results;
        };
      })(this);
      this.sectionCount = function () {
        return sectionAdapters.length;
      };
      this.hasMismatchedLengths = function () {
        return sectionAdapters.length !== block.sections.length;
      };
      this.calculateTotals = function () {
        return calculateQuote(
          block,
          new TaxService(accountQuoteSettings.taxes),
          variableService,
          discountService,
          billingScheduleOrchestrator,
          true,
        );
      };
      this.deleteSection = function (index) {
        if (sectionAdapters.length > 1) {
          block.sections.splice(index, 1);
          return sectionAdapters.splice(index, 1);
        } else {
          throw new Error("Quote block must have at least one section");
        }
      };
      this.createSection = function (index) {
        var newSectionAdapter, newSectionModel;
        newSectionModel = createSectionModel();
        block.sections.splice(index, 0, newSectionModel);
        newSectionAdapter = new SectionAdapter(newSectionModel, this.labelForField, variableService);
        sectionAdapters.splice(index, 0, newSectionAdapter);
        return newSectionAdapter;
      };
      this.cloneSection = function (index) {
        var clonedSectionAdapter, clonedSectionModel, sectionAdapter;
        sectionAdapter = sectionAdapters[index];
        clonedSectionModel = _.cloneDeep(sectionAdapter.model);
        if (block.selectionType === "single" && !!clonedSectionModel.selected) {
          clonedSectionModel.selected = false;
        }
        block.sections.splice(index, 0, clonedSectionModel);
        clonedSectionAdapter = new SectionAdapter(clonedSectionModel, this.labelForField, variableService);
        sectionAdapters.splice(index, 0, clonedSectionAdapter);
        return clonedSectionAdapter;
      };

      /*
        Swap the positions of 2 sections.
        Modifies both the model and the sectionAdapters array
       */
      this.swapSections = function (indexA, indexB) {
        var adapterToMove, modelToMove;
        adapterToMove = sectionAdapters[indexA];
        sectionAdapters[indexA] = sectionAdapters[indexB];
        sectionAdapters[indexB] = adapterToMove;
        modelToMove = block.sections[indexA];
        block.sections[indexA] = block.sections[indexB];
        return (block.sections[indexB] = modelToMove);
      };
      this.getTotal = function () {
        var ref;
        return (ref = block.$totals) != null ? ref.postTax : void 0;
      };
      this.getExTax = function () {
        var ref;
        return (ref = block.$totals) != null ? ref.preTax : void 0;
      };
      this.getTaxes = function () {
        var ref;
        return (ref = block.$totals) != null ? ref.totalTaxes : void 0;
      };

      /*
        @NOTE (som, 2018-11-19): note how @getPreDiscount() does not simply return
        `block.$totals?.preDiscount`.

        The whole idea of a 'pre-discount' total is a bit weird - because discounts are always
        calculated on pre-tax values, but we want the pre-discount total to actually be the
        'pre-discount, post-tax' total. We can't just apply taxes to the pre-discount total, because
        we have the concept of tax-exempt items.

        However, we do have the idea of a 'total discounts' now. This means that the final
        pre-discount total we display can simply be the post-tax total, plus all the discounts
       */
      this.getPreDiscount = function () {
        var ref;
        return ((ref = block.$totals) != null ? ref.postTax : void 0) + this.getAllDiscounts();
      };
      getViewSettings = function () {
        if (block.settings.view == null) {
          block.settings.view = {};
        }
        return block.settings.view;
      };
      this.showTaxTotals = function () {
        var ref;
        return (ref = block.settings.view) != null ? ref.showTaxTotals : void 0;
      };
      this.setShowTaxTotals = function (show) {
        return (getViewSettings().showTaxTotals = show);
      };
      this.showTotalDiscount = function () {
        var ref;
        return (ref = block.settings.view) != null ? ref.showTotalDiscount : void 0;
      };
      this.setShowTotalDiscount = function (show) {
        return (getViewSettings().showTotalDiscount = show);
      };
      this.showExTax = function () {
        var ref;
        return (ref = block.settings.view) != null ? ref.showExTax : void 0;
      };
      this.setShowExTax = function (show) {
        return (getViewSettings().showExTax = show);
      };
      this.showQuoteTotals = function () {
        var modelSetting;
        modelSetting = getViewSettings().showQuoteTotals;
        if (modelSetting != null) {
          return modelSetting;
        } else {
          return true;
        }
      };
      this.setShowQuoteTotals = function (show) {
        var telemetryAction;
        getViewSettings().showQuoteTotals = show;
        telemetryAction = show ? "Enabled" : "Disabled";
        return telemetry.track("Quote Block Show Total " + telemetryAction);
      };
      this.showSectionIds = function () {
        var modelSetting;
        modelSetting = getViewSettings().showSectionIds;
        if (modelSetting != null) {
          return modelSetting;
        } else {
          return false;
        }
      };
      this.setShowSectionIds = function (show) {
        var telemetryAction;
        getViewSettings().showSectionIds = show;
        telemetryAction = show ? "Enabled" : "Disabled";
        return telemetry.track("Quote Block Show Section Ids " + telemetryAction);
      };

      /*
        Whether to show digits after the decimal place for rates and costs
        See `CurrencyDecimalFormatter.js` for the formatting logic
       */
      this.showDecimalPlaces = function () {
        return getViewSettings().showDecimalPlaces;
      };
      this.setShowDecimalPlaces = function (show) {
        var telemetryAction;
        getViewSettings().showDecimalPlaces = show;
        telemetryAction = show ? "Enabled" : "Disabled";
        return telemetry.track("Quote Block Show Display Decimals " + telemetryAction);
      };
      this.getSelectionType = function () {
        return block.selectionType;
      };
      this.setSelectionType = function (selectionType) {
        var i, item, j, len, len1, ref, ref1, ref2, section;
        if (selectionType === "combined" || selectionType === "single" || selectionType === "multi") {
          if (block.selectionType !== selectionType) {
            ref = block.sections;
            for (i = 0, len = ref.length; i < len; i++) {
              section = ref[i];
              section.selected = false;
              section.mandatory = false;
              if (section.displayMode === "plans") {
                ref1 = section.items;
                for (j = 0, len1 = ref1.length; j < len1; j++) {
                  item = ref1[j];
                  if ((ref2 = item.interactive) != null ? ref2.isOptionalSelected : void 0) {
                    item.interactive.isOptionalSelected = false;
                  }
                }
              }
            }
          }
          return (block.selectionType = selectionType);
        } else {
          throw new Error("Invalid selection type");
        }
      };
      this.getSelectionRequired = (function (_this) {
        return function () {
          return block.settings.isSelectionRequired || false;
        };
      })(this);
      this.setSelectionRequired = (function (_this) {
        return function (required) {
          return (block.settings.isSelectionRequired = required);
        };
      })(this);

      /*
      Select a section
      Includes checks and modifications to ensure the model is consistent with business logic
       */
      this.selectSection = function (index, selected) {
        var i, len, ref, section;
        if (block.selectionType === "combined") {
          throw new Error("Cannot select sections when selectionType is 'combined'");
        }
        if (block.selectionType === "single") {
          ref = block.sections;
          for (i = 0, len = ref.length; i < len; i++) {
            section = ref[i];
            section.selected = false;
          }
        }
        return (block.sections[index].selected = selected);
      };
      this.getDiscountAdapter = (function (_this) {
        return function () {
          if (block.discount == null) {
            block.discount = createDiscountModel();
          }
          if (_this.discountAdapter == null) {
            _this.discountAdapter = new DiscountAdapter(block.discount);
          }
          return _this.discountAdapter;
        };
      })(this);
      this.labelForField = function (field) {
        var ref;
        return labelService.getLabel(
          (block != null ? ((ref = block.settings) != null ? ref.labelSetId : void 0) : void 0) || null,
          field,
        );
      };
      this.hasAnyDiscountApplied = (function (_this) {
        return function () {
          var i, itemAdapter, j, len, len1, ref, sectionAdapter;
          if (_this.discountAdapter.hasDiscount()) {
            return true;
          }
          for (i = 0, len = sectionAdapters.length; i < len; i++) {
            sectionAdapter = sectionAdapters[i];
            if (sectionAdapter.getDiscountAdapter().hasDiscount()) {
              return true;
            }
            ref = sectionAdapter.getItemAdapters();
            for (j = 0, len1 = ref.length; j < len1; j++) {
              itemAdapter = ref[j];
              if (itemAdapter.getDiscountAdapter().hasDiscount()) {
                return true;
              }
            }
          }
          return false;
        };
      })(this);
      this.getAllDiscounts = function () {
        var blockDiscount, ref, ref1;
        blockDiscount =
          ((ref = block.$totals) != null ? ref.preDiscount : void 0) -
            ((ref1 = block.$totals) != null ? ref1.preTax : void 0) || 0;
        if (block.selectionType === COMBINED_TYPE) {
          return blockDiscount + this.getSectionsDiscount(block.sections);
        }
        return blockDiscount + this.getSectionsDiscount(this.getSelectedSections(block.sections));
      };
      this.getIndividualDiscountPrice = function (item) {
        return item.$preDiscount - item.$cost || 0;
      };
      this.getSelectedItems = function (items) {
        return items.filter(
          (function (_this) {
            return function (item) {
              return (
                !item.interactive.isOptional || (item.interactive.isOptional && item.interactive.isOptionalSelected)
              );
            };
          })(this),
        );
      };
      this.getSelectedSections = function (sections) {
        return sections.filter(
          (function (_this) {
            return function (section) {
              return section.selected;
            };
          })(this),
        );
      };
      this.getSectionsDiscount = function (sections) {
        return sections.reduce(
          (function (_this) {
            return function (sum, section) {
              return (
                sum +
                _this.getIndividualDiscountPrice(section) +
                _this.getSelectedItems(section.items).reduce(function (itemSum, item) {
                  return itemSum + _this.getIndividualDiscountPrice(item);
                }, 0)
              );
            };
          })(this),
          0,
        );
      };
      init();
    }

    return QuoteAdapter;
  })();
}.call(this));

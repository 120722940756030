"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createHeading2Token = exports.Heading2Config = void 0;
var Heading2_css_1 = require("ContentTokens/SharedWidgetComponents/Text/Heading2.css");
var GenericText_1 = require("./GenericText");
var commonTypes_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/BlockConfig/commonTypes");
exports.Heading2Config = (0, GenericText_1.makeGenericTextConfig)("heading2", "h2", { vars: Heading2_css_1.vars, widget: Heading2_css_1.h2Widget, spacingShim: Heading2_css_1.h2SpacingShim }, {
    textColor: { themeIndex: 1 },
    fontSize: 40,
    lineHeight: Heading2_css_1.defaultLineHeight,
    blockSpacing: Heading2_css_1.defaultBlockSpacingEm,
    brandFontRef: commonTypes_1.TextElementType.H2,
});
var createHeading2Token = function () { return ({
    type: "block",
    subType: "heading2",
}); };
exports.createHeading2Token = createHeading2Token;

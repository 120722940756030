"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createCaptionTextToken = exports.CaptionTextConfig = void 0;
var CaptionText_css_1 = require("@CommonFrontend/ContentTokens/SharedWidgetComponents/Text/CaptionText.css");
var commonTypes_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/BlockConfig/commonTypes");
var GenericText_1 = require("./GenericText");
exports.CaptionTextConfig = (0, GenericText_1.makeGenericTextConfig)("captionText", "p", { vars: CaptionText_css_1.vars, widget: CaptionText_css_1.captionTextWidget, spacingShim: CaptionText_css_1.captionTextSpacingShim }, {
    textColor: "DERIVED_FROM_PARAGRAPH_HACK",
    fontSize: 14,
    lineHeight: CaptionText_css_1.defaultLineHeight,
    blockSpacing: CaptionText_css_1.defaultBlockSpacingEm,
    brandFontRef: commonTypes_1.TextElementType.P,
}, "captionText");
function createCaptionTextToken() {
    return {
        type: "block",
        subType: "captionText",
    };
}
exports.createCaptionTextToken = createCaptionTextToken;
